import * as React from 'react';
import { IdProvider } from 'react-use-id-hook';
import { Respondent } from 'client/respondent';
import { buildGraphqlClient } from 'client/shared/graphql-client';
import { AppErrorContextProvider } from 'client/shared/contexts/error-context';
import { FlagsContextProvider } from 'client/shared/contexts/flags-context';
import { ApolloProvider } from '@apollo/client';
import {
  integrationsInit,
  handleEvent,
  SocialLoginsImpl,
} from 'client/respondent/integrations';
import { createBrowserHistory as createHistory } from 'history';
import { Router } from 'react-router';
import { RespondentProvider } from 'client/respondent/core/reducers/context';
import 'client/styles/shared/main.scss';
import 'client/styles/respondent/main.scss';
import { renderRoot } from './shared';
import {
  BrowserContext,
  ClockContext,
  defaultBrowserHookInfo,
} from 'client/shared/hooks';
import { defaultClock } from 'core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'client/shared/core/theme';

integrationsInit();

const { graphqlClient, setErrorHandler } = buildGraphqlClient({
  name: 'respondent',
});
const history = createHistory({ basename: '/n' });
const clock = defaultClock();
const browserInfo = defaultBrowserHookInfo();

const RespondentRootWrapper: React.FC = (p) => {
  return (
    <RespondentProvider handleEvent={handleEvent} socialLogins={SocialLoginsImpl}>
      <AppErrorContextProvider externalErrorSource={setErrorHandler}>
        <ApolloProvider client={graphqlClient}>
          <FlagsContextProvider>
            <BrowserContext.Provider value={browserInfo}>
              <ClockContext.Provider value={clock}>
                <IdProvider prefix="res-id-hook-">
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Router history={history}>{p.children}</Router>
                    </LocalizationProvider>
                  </ThemeProvider>
                </IdProvider>
              </ClockContext.Provider>
            </BrowserContext.Provider>
          </FlagsContextProvider>
        </ApolloProvider>
      </AppErrorContextProvider>
    </RespondentProvider>
  );
};

renderRoot(
  <RespondentRootWrapper>
    <Respondent />
  </RespondentRootWrapper>
);
